<template>
  <v-container fluid class="d-flex flex-column">
    <v-card class="ma-2 pa-2 d-flex flex-column justify-space-between">
      <div id="map"></div>
    </v-card>
  </v-container>
</template>

<script>
import mapboxgl from "mapbox-gl";
import laos from "@/data/laos-provinces.js";
import links from "@/data/laos-links.js";

import { createApp, defineComponent, nextTick } from "@vue/composition-api";
import BridgePopup from "@/components/bridgeManagement/bridges/BridgePopup.vue";
import router from "@/router";

export default defineComponent({
  name: "BridgeMap",
  props: ["bridges"],
  watch: {
    bridges: {
      handler() {
        this.setBridges();
      },
    },
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/light-v10",
      center: [104, 18.4],
      zoom: 6,
    });

    this.mapPromise = new Promise((resolve) => {
      map.on("load", () => resolve(map));
    });

    this.mapPromise.then((map) => {
      map.addSource("bridges", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      map.addSource("links", {
        type: "geojson",
        data: links,
      });

      map.addSource("laos", {
        type: "geojson",
        data: laos,
      });

      map.addLayer({
        id: "laos",
        type: "fill",
        source: "laos",
        layout: {},
        paint: {
          "fill-color": "#CBD6DE",
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "laos-outline",
        type: "line",
        source: "laos",
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 1,
        },
      });

      map.addLayer({
        id: "links",
        type: "line",
        source: "links",
        layout: {},
        paint: {
          "line-color": "#ff0000",
          "line-width": 2,
        },
      });

      map.addLayer({
        id: "bridges",
        type: "circle",
        source: "bridges",
        paint: {
          "circle-radius": 4,
          "circle-color": [
            "case",
            ["<", ["get", "hi"], 20],
            "#FF0D0D",
            ["<", ["get", "hi"], 40],
            "#FF8E15",
            "#69B34C",
          ],
          "circle-stroke-color": "black",
          "circle-stroke-width": 1,
        },
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "500px",
        anchor: "left",
      });

      map.on("mouseenter", "bridges", (e) => {
        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const bridge = e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(coordinates)
          .setHTML('<div id="popup-content"></div>')
          .addTo(map);

        const bridgePopup = defineComponent({
          extends: BridgePopup,
          setup() {
            return { bridge };
          },
        });

        nextTick(() => {
          createApp(bridgePopup).mount("#popup-content");
        });
      });

      map.on("mouseleave", "bridges", () => {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      map.on("click", "bridges", (e) => {
        const bridge = e.features[0].properties;
        router.push({
          name: "bridge-inventory",
          params: { id: bridge.id, item: bridge },
        });
      });

      this.setBridges();
    });
  },
  methods: {
    setBridges() {
      this.mapPromise.then((map) => {
        const bridgeSource = map.getSource("bridges");
        if (!bridgeSource) {
          return;
        }
        bridgeSource.setData({
          type: "FeatureCollection",
          features: this.bridges.map((v) => ({
            type: "Feature",
            properties: {
              ...v,
              hi: Number(v.hi),
            },
            geometry: {
              type: "Point",
              coordinates: [v.bridge_gps_x, v.bridge_gps_y],
            },
          })),
        });
      });
    },
  },
});
</script>

<style>
#map {
  height: 85vh;
}
</style>