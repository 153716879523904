<template>
  <v-card class="mx-auto" outlined>
    <v-card-title
      ><h3>{{ bridge.bridge_name }}</h3></v-card-title
    >
    <v-card-subtitle
      ><h4>{{ bridge.roadnumber }} - {{ bridge.chainage }}</h4></v-card-subtitle
    >
    <v-card-text class="d-flex flex-column align-start">
      <div>
        <span class="mr-2">ແຂວງ:</span>
        <span class="font-weight-bold">{{ bridge.bridge_province }}</span>
      </div>
      <div>
        <span class="mr-2">ເມືອງ:</span>
        <span class="font-weight-bold">{{ bridge.bridge_district }}</span>
      </div>
      <div>
        <span class="mr-2">ຊື່ແມ່ນໍ້າ:</span>
        <span class="font-weight-bold">{{ bridge.river_name }}</span>
      </div>
      <div>
        <span class="mr-2">ຊ່ວງທາງ:</span>
        <span class="font-weight-bold">{{ bridge.linkid }}</span>
      </div>
      <div>
        <span class="mr-2">ຄ່າຄວາມສົມບູນຂອງຂົວ:</span>
        <span class="font-weight-bold">{{ bridge.hi }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>